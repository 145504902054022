var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1XAWCKgzOx0mVs3PGxaBz"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

import { HttpClient as HttpClientIntegration } from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;


const integrations = [
    new Sentry.BrowserTracing(),
    new HttpClientIntegration()
]


Sentry.init({
    dsn:
        SENTRY_DSN ||
        "https://7e03242358ae4882bbb36ca5d010a4e5@o1118876.ingest.sentry.io/6156879",
    environment:
        process.env.NODE_ENV === "production" ? "production" : "development",
    tracesSampleRate: 0.2,
    integrations: integrations
});
